import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { DateRangePicker  } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css';// theme css file


const App = () => {
    let moment = require('moment')
    const [dateRange, setDateRange] = useState({
        startDate: new Date(Number(moment(moment().subtract(30, 'days').format('YYYY-MM-DD')).format('x'))),
        endDate: new Date(Number(moment(moment().format('YYYY-MM-DD')).format('x'))),
        key: 'selection'
    })
    const [deviceId, setDeviceId] = useState([])

    const getDeviceId = (id) => {
        setDeviceId(
            id.target.value.split('\n').filter(function(value, index, arr){
                return value !== ''
            })
        )
    }


    let token = ''

    const postTokenApi = async () => {
        await fetch('https://apportal.azurewebsites.net/token', {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json', 
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+token
            },
            body: JSON.stringify({
                username: 'web',
                password: 'cGFzc3dvcmRfd2Vi'
            })
        })
        .then((res) => res.json())
        .then((resJson) => {
            token = resJson.access_token
        })
    }

    const dateSelect = (ranges) => {
        setDateRange(ranges.selection)
    }
   
    postTokenApi()
    
    return  (
        <div className='container'>
            <div className='calenderStyle'>
                        <DateRangePicker
                            ranges={[dateRange]}
                            onChange={dateSelect}
                        />
            </div>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column'
                }}
            >
                <TextField
                    id='deviceID'
                    label='Device ID'
                    multiline
                    sx={{width: '50ch', pl: 1}}
                    onChange={getDeviceId}
                />
                <Box
                    sx={{pt: 1, pl: 1}}
                >
                    <Button
                        variant='contained'
                        onClick={() => {
                            const postDataApi = async () => {
                                const respone = await fetch('https://apportal.azurewebsites.net/data/export', {
                                    method: 'POST',
                                    mode: 'cors',
                                    headers: {
                                        Accept: 'application/json', 
                                        'Content-Type': 'application/json',
                                        'authorization': 'Bearer '+token
                                    },
                                    body: JSON.stringify({
                                        start: moment(dateRange.startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
                                        end: moment(dateRange.endDate).add(23, 'hours').add(59, 'minutes').add(59, 'seconds').utc().format('YYYY-MM-DD HH:mm:ss'),
                                        device_id: deviceId.length?deviceId:['']
                                    })
                                })
                                .then(res => res.blob())
                                .then(blob => {
                                    let url = window.URL.createObjectURL(blob)
                                    let a = document.createElement('a')
                                    a.href = url
                                    a.download = 'data.zip'
                                    document.body.appendChild(a)
                                    a.click()
                                    a.remove()
                                })
                            }

                            postDataApi()
                        }}    
                    >
                        Submit
                    </Button>
                </Box>
            </Box>
        </div>
    )
}

ReactDOM.render(
    <App />,
    document.getElementById('root')
)
